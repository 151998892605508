import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React, { useCallback, useState } from 'react'
import * as Styled from './ManageableChatCard.styled'

import { CircularProgress, MenuItem, Snackbar } from '@material-ui/core'
import ChatPermissionsInstructions from 'components/ChatPermissionsInstructions/ChatPermissionsInstructions'
import Dialog from 'components/Dialog/Dialog'
import {
  deletePodcastTelegramChat,
  handleLinkTelegramChat,
  updateTelegramChatAccess,
} from 'services/api'
import { TelegramChatDTO } from 'types'

export interface ManageableChatCardProps {
  chat: TelegramChatDTO
  podcastId: string
  index: number
  fetchData: () => void
}

const ManageableChatCard: React.FC<ManageableChatCardProps> = ({
  chat,
  index,
  podcastId,
  fetchData,
}) => {
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isAccessLoading, setIsAccessLoading] = useState(false)
  const [accessValue, setAccessValue] = useState<'public' | 'supporters'>(
    chat.access,
  )
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )
  const hasPermissions = ['can_invite_users', 'can_restrict_members'].every(
    (permission) => chat.permissions.includes(permission),
  )

  const handleLink = useCallback(async () => {
    setIsActionLoading(true)

    const response = await handleLinkTelegramChat(podcastId, chat.id, true)

    if (response.error) {
      setIsActionLoading(false)
      setErrorMessage('Ocorreu um erro ao vincular o chat. Tente novamente.')
      return
    }

    setIsActionLoading(false)
    fetchData()
  }, [fetchData, podcastId, chat.id])

  const handleUnlink = useCallback(async () => {
    setIsActionLoading(true)

    const response = await handleLinkTelegramChat(podcastId, chat.id, false)

    if (response.error) {
      setIsActionLoading(false)
      setErrorMessage('Ocorreu um erro ao desvincular o chat. Tente novamente.')
      return
    }

    setIsActionLoading(false)
    fetchData()
  }, [fetchData, podcastId, chat.id])

  const handleDelete = useCallback(async () => {
    setIsActionLoading(true)

    const response = await deletePodcastTelegramChat(podcastId, chat.id)

    if (response.error) {
      setIsActionLoading(false)
      setErrorMessage('Ocorreu um erro ao deletar o chat. Tente novamente.')
      return
    }

    setIsActionLoading(false)
    fetchData()
  }, [fetchData, podcastId, chat.id])

  const handleAccessChange = useCallback(
    async (event: React.ChangeEvent<{ value: 'public' | 'supporters' }>) => {
      setIsAccessLoading(true)

      const response = await updateTelegramChatAccess(
        podcastId,
        chat.id,
        event.target.value,
      )

      if (response.error) {
        setIsAccessLoading(false)
        setErrorMessage(
          'Ocorreu um erro ao alterar o nível de acesso do chat. Tente novamente.',
        )
        return
      }

      setAccessValue(event.target.value)
      setIsAccessLoading(false)
    },
    [podcastId, chat.id],
  )

  const getIcon = (icon: JSX.Element) =>
    isActionLoading ? (
      <CircularProgress size={16} style={{ color: 'white' }} />
    ) : (
      icon
    )

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage(undefined)}
        message={errorMessage}
      />

      <Dialog
        dialogText={<ChatPermissionsInstructions />}
        handleCloseDialog={() => setIsInfoModalOpen(false)}
        isDialogOpen={isInfoModalOpen}
        noActionText="ok"
      />

      <Styled.CardContainer isOdd={index % 2 === 1}>
        <Styled.Cover>
          <Styled.TelegramIcon />
        </Styled.Cover>

        <Styled.Column style={{ flexGrow: 1 }}>
          <Styled.Title>{chat.title}</Styled.Title>
          <Styled.Id>id: {chat.chatId}</Styled.Id>
        </Styled.Column>

        <Styled.Actions>
          {chat.status === 'removed' ? (
            <>
              <Styled.Button variant="unlink" defaultCursor>
                Bot removido do grupo
                <CloseIcon />
              </Styled.Button>
              <Styled.Button
                onClick={handleDelete}
                variant="unlink"
                disabled={isActionLoading}
              >
                {getIcon(<DeleteOutlineIcon />)}
              </Styled.Button>
            </>
          ) : !hasPermissions ? (
            <Styled.Button
              onClick={() => setIsInfoModalOpen(true)}
              variant="alert"
            >
              Sem permissões necessárias
              <WarningAmberIcon />
            </Styled.Button>
          ) : chat.status === 'available' ? (
            <Styled.Button
              onClick={handleLink}
              disabled={isActionLoading}
              variant="link"
            >
              Vincular
              {getIcon(<LinkIcon />)}
            </Styled.Button>
          ) : chat.status === 'linked' ? (
            <Styled.Button
              onClick={handleUnlink}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              variant={isHovering ? 'unlink' : 'linked'}
              disabled={isActionLoading}
            >
              {isHovering ? 'Desvincular' : 'Vinculado'}
              {getIcon(isHovering ? <LinkOffIcon /> : <CheckIcon />)}
            </Styled.Button>
          ) : null}
        </Styled.Actions>

        {chat.status !== 'removed' && (
          <Styled.Actions>
            <Styled.StyledSelect
              value={accessValue}
              onChange={handleAccessChange}
              disabled={isAccessLoading}
            >
              <MenuItem value="public">Público</MenuItem>
              <MenuItem value="supporters">Somente apoiadores</MenuItem>
            </Styled.StyledSelect>
            {isAccessLoading && (
              <CircularProgress size={20} style={{ color: 'black' }} />
            )}
          </Styled.Actions>
        )}
      </Styled.CardContainer>
    </>
  )
}

export default ManageableChatCard
