import React from 'react'
import * as Styled from './ChatPermissionsInstructions.styled'

const ChatPermissionsInstructions: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Title>Como adicionar as permissões necessárias?</Styled.Title>

      <Styled.Instructions>
        <li>Acesse a edição do grupo.</li>
        <li>Abra a seção "Administradores".</li>
        <li>Acesse o botão de adição "+".</li>
        <li>Escolha o @OreloStagingBot.</li>
        <li>
          Garanta que as permissões "Remover usuários" e "Adicionar usuários"
          estejam marcadas.
        </li>
        <li>Confirme a adição.</li>
        <li>Atualize os grupos nessa página clicando em "Atualizar".</li>
      </Styled.Instructions>
    </Styled.Container>
  )
}

export default ChatPermissionsInstructions
