import styled from 'styled-components'
import { Colors } from 'styles'
import { LETTER_SPACING_1 } from 'styles/typography'
import { ReactComponent as Padlock } from '../../assets/icons/padlock_fill.svg'
import { ReactComponent as TelegramIconSvg } from '../../assets/icons/telegram.svg'

export const CardContainer = styled.div<{ isOdd?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: ${({ isOdd }) => (isOdd ? Colors.GREY[50] : Colors.WHITE)};
  padding: 8px 0px;
`

export const Cover = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`

export const TelegramIcon = styled(TelegramIconSvg)``

export const Title = styled.span`
  font-family: SpaceGrotesk;
  margin-left: 16px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -${LETTER_SPACING_1}px;
  display: flex;
  align-items: center;
  flex-grow: 1;
`

export const Actions = styled.div`
  margin-right: 10px;
`

export const PadlockWithMargin = styled(Padlock)`
  margin-left: 8px;
`
