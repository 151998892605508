import { LoginButton } from '@telegram-auth/react'
import React, { useCallback, useEffect, useState } from 'react'

import Info from '@mui/icons-material/Info'
import SyncIcon from '@mui/icons-material/Sync'
import Button from 'components/Button/Button'
import ChatAccountCard from 'components/ChatAccountCard/ChatAccountCard'
import ManageableChatCard from 'components/ManageableChatCard/ManageableChatCard'
import { useParams } from 'react-router-dom'
import {
  getPodcastTelegramAccount,
  getPodcastTelegramChats,
  linkPodcastTelegramAccount,
  TelegramUser,
} from 'services/api'
import { Config } from 'settings/config'
import { PodcastTelegramAccountDTO, TelegramChatDTO } from 'types'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './ManageChatsPage.styled'
import Dialog from 'components/Dialog/Dialog'
import ChatBotInstructions from 'components/ChatBotInstructions/ChatBotInstructions'
import AccountLinkInstructions from 'components/AccountLinkInstructions/AccountLinkInstructions'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const ManageChatsPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const [isChatBotInstructionsOpen, setIsChatBotInstructionsOpen] =
    useState<boolean>(false)
  const [isAccountLinkInstructionsOpen, setIsAccountLinkInstructionsOpen] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [telegramAccount, setTelegramAccount] = useState<
    PodcastTelegramAccountDTO | undefined
  >(undefined)
  const [chats, setChats] = useState<TelegramChatDTO[]>([])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setHasError(false)

    const [accountResponse, chatsResponse] = await Promise.all([
      getPodcastTelegramAccount(podcastId),
      getPodcastTelegramChats(podcastId),
    ])

    if (
      chatsResponse.error ||
      (accountResponse.error && accountResponse.error.status !== 404)
    ) {
      setHasError(true)
      setIsLoading(false)
      return
    }

    setTelegramAccount(accountResponse.data)
    setChats(chatsResponse.data)
    setIsLoading(false)
  }, [podcastId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleLinkAccount = useCallback(
    async (user: TelegramUser) => {
      setIsLoading(true)
      setHasError(false)

      const response = await linkPodcastTelegramAccount(podcastId, user)

      if (response.error?.message === 'Account is linked with other podcast') {
        setIsAccountLinkInstructionsOpen(true)
        setIsLoading(false)
        return
      }

      if (response.error) {
        setHasError(true)
        setIsLoading(false)
        return
      }

      setTelegramAccount(response.data)
      setIsLoading(false)
    },
    [podcastId],
  )

  const getBotLink = () => (
    <Styled.BotLink onClick={() => setIsChatBotInstructionsOpen(true)}>
      @{Config.TELEGRAM_BOT_USERNAME}
    </Styled.BotLink>
  )

  if (isLoading) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (hasError) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que tivemos um erro aqui.
          </Styled.MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Dialog
        dialogText={<ChatBotInstructions />}
        handleCloseDialog={() => setIsChatBotInstructionsOpen(false)}
        isDialogOpen={isChatBotInstructionsOpen}
        noActionText="ok"
      />

      <Dialog
        dialogText={<AccountLinkInstructions />}
        handleCloseDialog={() => setIsAccountLinkInstructionsOpen(false)}
        isDialogOpen={isAccountLinkInstructionsOpen}
        noActionText="ok"
      />

      <Styled.Wrapper>
        <Styled.Title>
          Telegram <Styled.TelegramIcon />
        </Styled.Title>

        <Styled.InformativeText>
          <p>
            Na Orelo, você consegue vincular seus grupos do Telegram aos seus
            apoiadores.
          </p>
          <p>
            Ou seja, com tudo vinculado e sincronizado, quando um novo fã te
            apoiar, automaticamente ele terá acesso ao link do grupo para fazer
            parte diretamente no Telegram.
          </p>
          <p>
            Caso ele deixe de ser um apoiador, ele também deixa de fazer parte
            do grupo automaticamente.
          </p>
          <p>Para isso, siga os passos a seguir:</p>
          <ul>
            <li>Vincule seu Telegram ao seu projeto pelo botão abaixo </li>
            <li>Adicione o Bot da Orelo ao seu grupo e torne-o admin;</li>
            <li>
              Atualize e veja o grupo aqui embaixo e em seguida clique em
              "vincular"
            </li>
            <li>Prontinho =)</li>
          </ul>
        </Styled.InformativeText>

        <Styled.LinkedAccount>
          {telegramAccount ? (
            <ChatAccountCard
              avatar={telegramAccount.avatar}
              name={telegramAccount.name}
              id={telegramAccount.userId}
            />
          ) : (
            <LoginButton
              onAuthCallback={handleLinkAccount}
              botUsername={Config.TELEGRAM_BOT_USERNAME}
              buttonSize="large"
              requestAccess="write"
              showAvatar
              lang="pt-br"
              widgetVersion={22}
            />
          )}
        </Styled.LinkedAccount>
        <Styled.Title style={{ marginTop: 32 }}>
          Grupos
          <Styled.SyncButton onClick={fetchData}>
            Atualizar
            <SyncIcon fontSize="medium" />
          </Styled.SyncButton>
        </Styled.Title>
        <Styled.ChatsInfo>
          <Info fontSize="small" />
          <span>
            A conta vinculada deve adicionar o {getBotLink()} no grupo para
            aparecer aqui.
          </span>
        </Styled.ChatsInfo>
        {chats.length === 0 && (
          <Styled.EmptyChatsText>
            Nenhum grupo encontrado. Adicione o {getBotLink()} como
            administrador.
          </Styled.EmptyChatsText>
        )}
        {chats.map((chat, index) => (
          <ManageableChatCard
            key={chat.id}
            chat={chat}
            podcastId={podcastId}
            fetchData={fetchData}
            index={index}
          />
        ))}
      </Styled.Wrapper>
    </BasePage>
  )
}

export default ManageChatsPage
