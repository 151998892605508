import React from 'react'
import styled from 'styled-components'
import { ListenerTelegramChatDTO } from '../../types'
import ChatCard from 'components/ChatCard/ChatCard'

const TabListWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`

interface ChatsTabProps {
  podcastId: string
  chats: ListenerTelegramChatDTO[]
  fetchChats: () => Promise<void>
}

const ChatsTab: React.FC<ChatsTabProps> = ({
  chats,
  podcastId,
  fetchChats,
}) => {
  return (
    <TabListWrapper>
      {chats.map((chat, index) => (
        <ChatCard
          key={chat.id}
          podcastId={podcastId}
          chatId={chat.id}
          title={chat.title}
          userHasAccess={chat.userHasAccess}
          joined={chat.joined}
          fetchChats={fetchChats}
          index={index}
        />
      ))}
    </TabListWrapper>
  )
}

export default ChatsTab
